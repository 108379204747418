<template>
  <div class="box">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ surveyTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="survey">
      <div class="survey-header">
        <h2>
          <b>{{ surveyTitle }}</b>
        </h2>
      </div>
      <p class="mt-5 mb-5">{{ surveyDescription }}</p>
      <div class="survey-questions" v-if="student">
        <div v-for="(question, index) in questions" :key="index">
          <div class="d-flex">
            <b class="text-danger mr-2">*</b>
            <div>
              <h2>
                <b>
                  {{ question.question }}
                </b>
              </h2>
              <p>{{ question.description }}</p>

              <el-input
                type="textarea"
                :rows="6"
                v-model="student[question.prop]"
              ></el-input>
            </div>
          </div>

          <div class="question-separator"></div>
        </div>

        <div class="survey-submit">
          <el-button type="primary" @click="submitSurvey">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import profileApi from "@/apis/profile";
export default {
  components: {
    Breadcrumb
  },
  name: "Survey",
  async mounted() {
    const res = await profileApi.getUserData(this.studentUserId);
    this.student = res.student;
  },
  data() {
    return {
      student: null,
      surveyTitle: this.$t("survey.title"),
      surveyDescription: this.$t("survey.title-text"),
      questions: [
        {
          question: this.$t("survey.Q1"),
          description: this.$t("survey.Q1-text"),
          prop: "ideal_college_major",
          answer: ""
        },
        {
          question: this.$t("survey.Q2"),
          description: this.$t("survey.Q2-text"),
          prop: "dream_schools",
          answer: ""
        },
        {
          question: this.$t("survey.Q3"),
          description: this.$t("survey.Q3-text"),
          prop: "current_situation",
          answer: ""
        },
        {
          question: this.$t("survey.Q4"),
          description: this.$t("survey.Q4-text"),
          prop: "how_can_we_help",
          answer: ""
        },
        {
          question: this.$t("survey.Q5"),
          description: this.$t("survey.Q5-text"),
          prop: "ideal_counselor",
          answer: ""
        },
        {
          question: this.$t("survey.Q6"),
          description: this.$t("survey.Q6-text"),
          prop: "availability_to_meet",
          answer: ""
        }
      ]
    };
  },
  computed: {
    // ...mapState("user", ["token", "lang"]),
    // ...mapGetters("user", ["token", "getProfile"]),
    studentUserId() {
      return this.$route.query.student;
    }
  },
  methods: {
    async submitSurvey() {
      // 处理提交逻辑
      const res = await profileApi.patchStudentProfileByAdmin(
        this.studentUserId,
        {
          ideal_college_major: this.student.ideal_college_major,
          dream_schools: this.student.dream_schools,
          current_situation: this.student.current_situation,
          how_can_we_help: this.student.how_can_we_help,
          ideal_counselor: this.student.ideal_counselor,
          availability_to_meet: this.student.availability_to_meet
        }
      );
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.$router.go(-1);
    }
  }
};
</script>

<style>
.box {
  max-width: 800px;
  margin: 0 auto 2rem;
}
.survey {
  font-family: "Helvetica Neue", sans-serif;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.survey-header {
  text-align: center;
  margin-bottom: 10px;
}

.survey-header h2 {
  margin: 0;
}

.survey-header p {
  font-size: 18px;
  margin-top: 10px;
}

.survey-questions {
  background-color: white;
  /* padding: 30px; */
  border: none;
}

.survey-questions h2 {
  font-size: 18px;
  margin-bottom: 5px;
}
.survey-questions p {
  color: #838383;
  font-size: 14px;
  line-height: 20px;
}

.question-separator {
  margin: 40px 0;
  border-top: 1px solid #ccc;
}

.survey-submit {
  text-align: center;
  margin-top: 2rem;
}

::v-deep .el-input {
  margin-top: 10px;
}

::v-deep .el-input__inner {
  font-size: 18px;
  color: #333;
}
@media screen and (max-width: 992px) {
  .survey {
    font-family: "Helvetica Neue", sans-serif;
    max-width: 800px;
    margin: 0;
    padding: 2rem 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border: none;
    box-shadow: none;
  }
}
</style>
